import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { transform, isEqual, isObject, trimStart } from 'lodash';
import { AuthConfig } from 'angular-oauth2-oidc';
import * as Sentry from '@sentry/angular';
import { jwtDecode } from 'jwt-decode';
import { MeaChatSconnectService } from 'mea-chat-libraries';

import {
    now,
    compareDates,
    convertFromMillisecondsToLocalTime
} from '../../formatting/date.formatting';
import { ProfileSettingInterface } from '../../interfaces/settings.interface';
import { ToastService } from '../toast.service';
import { AppRoutesEnum } from '../../enums/routes.enum';
import { CompareDateEnum } from '../../enums/date.enum';
import { CustomerPositionEnum } from '../../enums/customer.enum';
import { AuthStorageKeyEnum } from '../../enums/authStorageKey.enum';
import { environment } from '../../../../environments/environment';
import { PharmacyStateVar } from '../../store/locals/pharmacyState.var';
import { ProfileSettingsVar } from '../../store/locals/profileSettings.var';
import { MaintenanceStateVar } from '../../store/locals/maintenanceState.var';
import { AuthQueries } from '../../store/graphql/queries/auth.graphql';

import { SSOAuthenticationService } from './sso-auth.service';
import { WaitingRoomEnum } from '../../enums/waiting-room.enum';
import { ModuleActivationStateVar } from '../../store/locals/moduleActivationState.var';
import { HiddenUserAccessRightsEnum, UserAccessRightsEnum } from '../../enums/user-administration.enum';
import { unsubscribe, unsubscribeAll } from '../../core.utils';
import { DataChangedService } from '../dataChanged.service';
import { AuthMutations } from '../../store/graphql/mutations/auth.graphql';

export const authConfig: AuthConfig = {
    // Url of the Identity Provider
    issuer: environment.sso.baseUrl,
    redirectUri: window.location.origin + '/login/callback',

    // The SPA's id. The SPA is registered with this id at the auth-server
    // clientId: 'server.code',
    clientId: 'sanacorp-connect',

    // Just needed if your auth server demands a secret. In general, this
    // is a sign that the auth server is not configured with SPAs in mind
    // and it might not enforce further best practices vital for security
    // such applications.
    // dummyClientSecret: 'secret',

    responseType: 'code',

    // set the scope for the permissions the client should request
    // The first four are defined by OIDC.
    // The api scope is a usecase specific one
    scope: 's_connect',

    showDebugInformation: false,
    strictDiscoveryDocumentValidation: false
};


@Injectable({
    providedIn: 'root',
})
export class SconnectAuthService {
    static tokenInterval;

    private http = inject(HttpClient);
    private apollo = inject(Apollo);
    private router = inject(Router);
    private authQueries = inject(AuthQueries);
    private pharmacyStateVar = inject(PharmacyStateVar);
    private profileSettingsVar = inject(ProfileSettingsVar);
    private maintenanceStateVar = inject(MaintenanceStateVar);
    private moduleActivationStateVar = inject(ModuleActivationStateVar);
    private ssoAuthService = inject(SSOAuthenticationService);
    private toastService = inject(ToastService);
    private dataChangedService = inject(DataChangedService);
    private location = inject(Location);
    private authMutations = inject(AuthMutations);
    private meaChatSconnectService = inject(MeaChatSconnectService);


    isNewUser = false;
    profile: ProfileSettingInterface;
    maintenanceSubscription: Subscription;
    moduleActivationSubscription: Subscription;
    maintenanceInterval;
    accessToken: string;
    lastUpdate = null;

    constructor() {
        void this.configure();
        void this._decodeToken();

    }

    /**
     * Sets the access token to CDN
     * @private
     */
    private async setCdnToken() {
        const newAccessToken  = localStorage.getItem(AuthStorageKeyEnum.accessToken);
        if(newAccessToken === this.accessToken) {
            return; // Do not set the same access token twice
        }
        this.accessToken = newAccessToken;
        const params = new URLSearchParams();
        params.append('token', newAccessToken);
        try {
            await new Promise(((resolve, reject) => {
                this.http.post(
                    environment.mediaS3Uri + 'setToken',
                    params,
                    {
                        withCredentials: true
                    }
                ).subscribe({next: resolve, error: reject});
            }));
        } catch (e) {
            // Do nothing
            this.accessToken = null;
        }
    }

    /**
     * Sets the access token to CDN
     * @private
     */
    private async resetCdnToken() {
        try {
            await new Promise(((resolve, reject) => {
                this.http.post(
                    environment.mediaS3Uri + 'resetToken',
                    null,
                    {
                        withCredentials: true
                    }
                ).subscribe({next: resolve, error: reject});
            }));
        } catch (e) {
            // Do nothing
        }
    }

    /**
     * Returns the access token - requests new if old one is already expired
     * @returns Promise<string>
     */
    public async checkAccessToken() : Promise<boolean> {
        const expiresAtFromLocalStorage = localStorage.getItem(AuthStorageKeyEnum.expiresAt);
        const expiresAt = expiresAtFromLocalStorage ? convertFromMillisecondsToLocalTime(
            parseInt(expiresAtFromLocalStorage, 10),
            false
        ) : null;

        const refreshToken = localStorage.getItem(AuthStorageKeyEnum.refreshToken);
        if (!refreshToken || refreshToken.length === 0) {
            return false;
        }
        const accessToken = localStorage.getItem(AuthStorageKeyEnum.accessToken);
        if (!accessToken || accessToken.length === 0 ||
            !expiresAt || compareDates(expiresAt, now(), CompareDateEnum.diffInMinutes) <= 3
        ) {
            await this.configure();
            const success = await this.ssoAuthService.getAccessToken(true);
            if(success) {
                if (!this.lastUpdate || compareDates(now(), this.lastUpdate, CompareDateEnum.diffInMinutes) > 1) {
                    this.updatePharmacyStatus();
                    this.lastUpdate = now();
                }
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    updatePharmacyStatus = () => {
        void this.setCdnToken(); // Login to CDN
        this.authMutations.updatePharmacyStatus();
        void this._decodeToken();
    }

    clearMaintenancePolling() {
        unsubscribeAll([
            this.maintenanceSubscription
        ]);
        this.clearMaintenanceIntervals();
    }

    /**
     * maintenance polling
     */
    maintenancePolling() {
        this.checkIsMaintenance();
        this.clearMaintenanceIntervals();
        this.maintenanceInterval = setInterval(() => {
            this.checkIsMaintenance();
        }, environment.pollingInterval.user);
    }

    /**
     * Check if maintenance page
     */
    public checkIsMaintenance() {
        unsubscribe(this.maintenanceSubscription);
        try {
            // this is to ensure that developer can skip over the maintenance page
            if (localStorage.getItem('disable_maintenance_mode') === 'true') {
                this.maintenanceStateVar.set(false, '');
                return;
            }
            this.http.get(environment.mediaS3Uri + 'maintenance.json?t=' + new Date().getTime())
              .subscribe({
                  next: async (response: any) => {
                      const maintenance = response;
                      if (maintenance && Array.isArray(maintenance) && maintenance.length > 0) {
                          let hasMaintenance = false;
                          maintenance.forEach((m) => {
                              if (compareDates(m['start'], now(), CompareDateEnum.isSameOrBefore)) {
                                  this.maintenanceStateVar.set(true, m['text'] ? m['text'] : '');
                                  const refreshToken = localStorage.getItem(AuthStorageKeyEnum.refreshToken);
                                  if (refreshToken && refreshToken.length > 0) {
                                      this.setCdnToken(); // Login to CDN
                                      this.authMutations.updatePharmacyStatus();
                                      this.router.navigate([AppRoutesEnum.waiting, {type: WaitingRoomEnum.isMaintenance}]);
                                  }
                                  hasMaintenance = true;
                              }
                          });
                          if (!hasMaintenance) {
                              this.maintenanceStateVar.set(false, '');
                          }
                      } else {
                          this.maintenanceStateVar.set(false, '');
                      }
                  }
              });
        } catch (e) {
            // Do nothing
        }
    }

    public clearMaintenanceIntervals = () => {
        clearInterval(this.maintenanceInterval);
    }

    /**
     * Polling for user profile and pharmacy updates
     */
    pollingUserProfile() {
        this.getModuleActivation();
        // update profile date via SsoRefreshToken every 900 seconds
        this.maintenancePolling();
    }

    /**
     * Deep diff between two object, using lodash
     * @param  object Object compared
     * @param  base   Object to compare with
     * @return Return a new object who represent the diff
     */
     difference(object, base) {
        return transform(object, (result, value, key) => {
            if (!isEqual(value, base[key])) {
                result[key] = isObject(value) && isObject(base[key]) ? this.difference(value, base[key]) : value;
            }
        });
    }

    /**
     * Get Modules that are unlocked for the user.
     */
    getModuleActivation() {
        unsubscribe(this.moduleActivationSubscription);
        this.moduleActivationSubscription = this.authQueries.getActiveModules().subscribe(activeModules => {
            this.moduleActivationStateVar.set(activeModules);
        });
    }

    /**
     * Clear Subscriptions and intervals
     */
    destroy() {
        unsubscribeAll([
            this.moduleActivationSubscription
        ]);
        this.clearMaintenancePolling();
    }

    /**
     * Returns true if the user is logged in
     */
    isLoggedIn() {
        return new Promise(resolve => {
            const refreshToken = localStorage.getItem(AuthStorageKeyEnum.refreshToken);
            resolve(refreshToken && refreshToken.length > 0);
        });
    }


    // ---------------------------------------------------------------------------------------------------------------
    // LOGIN FLOW
    // ---------------------------------------------------------------------------------------------------------------


    public async configure() {
        await this.ssoAuthService.configure(authConfig, 'sconnect_');
    }

    /**
     * Initializes the login flow in a popup
     *
     * @returns Promise<unknown> returns a promise on success
     */
    public async login(): Promise<void> {
        await this.configure();

        // Open up the window immediately after the user has clicked the button
        this.ssoAuthService.initLoginFlow(false).then(async isSuccess => {
            if(isSuccess === false) {
                await this.logout(false);
                await this.toastService.presentError(
                    'Bei der Anmeldung ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.'
                );
            }  else if(isSuccess === true) {
                const hasToken = this._decodeToken();
                if(hasToken) {
                    this.apollo.client.stop();
                    await this.setCdnToken(); // Login to CDN
                    await this.authMutations.updatePharmacyStatus();
                    await this.router.navigate([AppRoutesEnum.waiting, { isLogin: 1}]);
                } else {
                    await this.logout(false);
                }
            }
        });
    }

    public tryResolveLogin(): Promise<boolean> {
        return new Promise(resolve => {
            // Open up the window immediately after the user has clicked the button
            this.ssoAuthService.tryResolveLogin().then(async isLoggedIn => {
                if(isLoggedIn) {
                    const hasToken = this._decodeToken();
                    if(hasToken) {
                        this.apollo.client.stop();
                        await this.setCdnToken(); // Login to CDN
                        await this.authMutations.updatePharmacyStatus();
                        // Check for cndReferer in local storage and redirect to it if it exists, make sure to remove it afterwards
                        const cdnReferer = localStorage.getItem('cdnReferer');
                        if(cdnReferer) {
                            localStorage.removeItem('cdnReferer');
                            window.location.href = environment.mediaS3Uri + trimStart(cdnReferer, '/');
                        } else {
                            await this.router.navigate([AppRoutesEnum.waiting, {isLogin: 1}]);
                        }
                        resolve(true);
                    } else {
                        await this.logout(false);
                        resolve(false);
                    }
                } else {
                    await this.logout(false);
                    await this.toastService.presentError(
                        'Bei der Anmeldung ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.'
                    );
                    resolve(false);
                }
            });
        });
    }

    public async refreshToken(): Promise<boolean> {
        await this.configure();
        return this.ssoAuthService.getAccessToken();
    }

    /**
     * Removes all meamind keys from local storage
     */
    public logout(showToast = true, logoutAll = true, paramExtension = ''): Promise<void> {
        void this.meaChatSconnectService.logoutFromMeaChat();
        return new Promise(resolve => {
            const toDelete = [];
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (/^sconnect_.*/.test(key) && key !== AuthStorageKeyEnum.activePharmacy) {
                    toDelete.push(key);
                }
            }
            toDelete.map((key) => localStorage.removeItem(key));

            this.apollo.client.stop();
            this.apollo.client.clearStore().then(async () => {
                if (showToast) {
                    await this.toastService.presentSuccess('Erfolgreich abgemeldet');
                }
                // Reset the CDN login
                await this.resetCdnToken();

                if (logoutAll) {
                    location.href = environment.sso.baseUrl + '/protocol/openid-connect/logout?redirect_uri=' +
                        encodeURIComponent(window.location.origin + '/login?logoutsuccess=true' + paramExtension);
                    resolve();
                } else {
                    await this.router.navigateByUrl(AppRoutesEnum.login);
                    resolve();
                }
            });
        });
    }

    /**
     * Simple logout and page refresh, no cache clean
     */
    async logoutAndRefresh(isLoggedIn = true) {
        if (isLoggedIn) {
            await this.logout(isLoggedIn, isLoggedIn, '&forcereload=true');
        } else {
            window.location.reload();
        }
    }

    /**
     * Decode token to retrieve user information
     */
    private async _decodeToken() {
        const token = localStorage.getItem(AuthStorageKeyEnum.accessToken);
        if(!token) {
            return false;
        }
        const decodedToken: {
            sub: string,
            email: string,
            resource_access: Array<string>
        } = jwtDecode(token);
        const pharmacies = [];

        if(decodedToken) {
            // Set Profile Settings
            await this.profileSettingsVar.setNewProfileData(this._getProfileSettings(decodedToken));

            // Configure Sentry Scope
            Sentry.getCurrentScope().setUser({
                id: decodedToken.sub ? decodedToken.sub.toString() : '',
                username: decodedToken.email ? decodedToken.email.toString() : ''
            });

            // Get available pharmacies
            if(decodedToken.resource_access
                && decodedToken.resource_access['sanacorp-connect']
                && Array.isArray(decodedToken.resource_access['sanacorp-connect'].roles)) {

                const allGroupsParsed = decodedToken.resource_access['sanacorp-connect'].roles
                    .map((g) => g.match(new RegExp('(.*)_(.*)_(.*)')));

                for (const group of allGroupsParsed) {
                    if (Array.isArray(group) && group.length === 4) {
                        if (group[2] === 's-connect'
                            && [UserAccessRightsEnum.PHARMACY_MEMBER, UserAccessRightsEnum.PHARMACY_OWNER].includes(group[3])) {
                            // Continue since this role is for another app
                            pharmacies.push(group[1]);
                        }
                    }
                }
            }
        }

        // Set active pharmacy if there is no one active
        if(pharmacies && pharmacies.length > 0) {
            const activePharmacy = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
            if (
                !activePharmacy ||
                !(pharmacies.find((group) => group.toString() === activePharmacy.toString()))
            ) {
                this.pharmacyStateVar.setActivePharmacy(pharmacies[0]);
                return true;

            } else if (activePharmacy && !pharmacies.find((group) => group.includes(activePharmacy))) {
                await this.logoutAndKeepMessage('Die verwendete Filiale ist Ihnen nicht länger zugeordnet. Sie werden abgemeldet.');

            } else {
                return true;
            }
        } else {
            await this.logoutAndKeepMessage('Kein Zugriff auf Apothekendaten möglich. Bitte wenden Sie sich an Ihren Sanacorp Ansprechpartner.');
        }
        return false;
    }

    private logoutAndKeepMessage = async (errorMessage) => {
        await this.toastService.presentError(errorMessage);
        localStorage.setItem(this.toastService.logoutMessageKey, errorMessage);
        // display error message for 1 second before logout
        await new Promise(async (resolve) => {
            setTimeout(() => {resolve(true);}, 1000);
        });
        await this.logout(false);
    }

    /**
     * Map profile model from server to local model
     *
     * @param profileData - profile data from server
     */
    private _getProfileSettings = (profileData) => {
        if (!profileData) {
            return;
        }
        const isPharmacyOwner = profileData && profileData.resource_access && profileData.resource_access['sanacorp-connect'] &&
            profileData.resource_access['sanacorp-connect'].roles &&
            Array.isArray(profileData.resource_access['sanacorp-connect'].roles) &&
            profileData.resource_access['sanacorp-connect'].roles.findIndex(role => role.includes(UserAccessRightsEnum.PHARMACY_OWNER)) > -1;
        const avatar =  profileData.avatar ? profileData.avatar : null;
        const customerNumber = (profileData.sc && profileData.sc.pharmacy_id) ? profileData.sc.pharmacy_id : '';
        let position = '';
        if (profileData.sc && profileData.sc.position) {
            switch (profileData.sc.position) {
                case CustomerPositionEnum.pharmaceuticalPersonal:
                    position = CustomerPositionEnum.pharmaceuticalPersonal;
                    break;
                case CustomerPositionEnum.pharmacist:
                    position = CustomerPositionEnum.pharmacist;
                    break;
                default:
                    position = '';
            }
        }
        // push the roles into a list
        const userAccessRights =  profileData.resource_access['sanacorp-connect'].roles;
        const chatRoles = profileData.resource_access['meadirekt-chat']?.roles || [];

        if (profileData.sc?.isVertreter) {
            const activePharmacy = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
            userAccessRights.push(activePharmacy + '_s-connect' + '_' + HiddenUserAccessRightsEnum.REPRESENTATIVE);
        }

        return {
            user: {
                id: profileData.sub,
                prefix: (profileData.sc && profileData.sc.prefix) ? profileData.sc.prefix : '',
                title: (profileData.sc && profileData.sc.title) ? profileData.sc.title : '',
                firstName: profileData.given_name ? profileData.given_name : '',
                lastName: profileData.family_name ? profileData.family_name : '',
                email: profileData.email,
                position,
                isPharmacyOwner,
                avatar,
                userAccessRights,
                chatRoles
            },
            customerNumber,
            lastAuth: {
                lastAuthDateTime: now()
            },
            contactNames: []
        };
    };

    /**
     * Check if logout success is existing in query params, if yes show logout success toast. This param is added by keycloak on logout.
     */
    public async checkIsLoggedOut() {
        const params = (new URL(document.location.toString())).searchParams;
        const logoutMessage = localStorage.getItem(this.toastService.logoutMessageKey);
        if(params && params.has('logoutsuccess') && !logoutMessage) {
            await this.toastService.presentSuccess('Erfolgreich abgemeldet');
            this.location.replaceState('login');
            if(params.has('forcereload')) {
                window.location.reload();
            }
        }
    }
}
